<template>
  <div class="actions">
    <a
      href="javascript:void(0)"
      class="btn btn-primary"
      @click.prevent="setSelected"
      data-toggle="modal"
      data-target="#update-fiche-type"
    ><i class="icofont icofont-pencil" /></a>
    <a
      href="javascript:void(0)"
      class="btn btn-third"
      @click.prevent="deleting"
    ><i class="icofont icofont-close" /></a>
  </div>
</template>
        
        <script>
        import {mapGetters, mapMutations} from 'vuex'
        import {  DELETE_TYPE_FICHE_ANAYLSE } from '../../../../graphql/ficheAnalyse';
        export default {
            props: {object: {type: Object, required: true}},
            data(){
                return{
        
                }
            },
            methods: {
                ...mapMutations({
                    setSelectedObject: 'SET_SELECTED_OBJECT',
                }),
                setSelected(){
                    this.setSelectedObject(this.object)
                },
                deleting(){
                    console.log('Deleting Parametre')
                    this.$apollo.mutate({
                        mutation: DELETE_TYPE_FICHE_ANAYLSE,
                        variables: {
                            "uid": this.object.uid
                        },
                        update: (data) => {
                            if(data) console.log(`Type fiche analyse ${this.object.libelle} at ${this.object.uid} deleted successfully`)
                            else console.log(`Error in deletting type fiche analyse ${this.object.libelle}`)
                        }
                      }).then(() => {
                        
                      })
                }
            },
            computed: {
                ...mapGetters({
        
                })
            }
        }
        </script>
        
        <style>
        
        </style>